import { banquetSingleSpaReact } from 'banquet-runtime-modules'
import { singleSpaCssLifecycles } from '@toasttab/banquet-single-spa-css'
import { RootComponent } from './app/RootComponent'
import './index.css'

const reactLifecycles = banquetSingleSpaReact({
  singleSpaCssLifecycles,
  cssScope: 'data-ec-admin-navigation-spa',
  domElementGetter: () => document.getElementById('page-nav')!,
  rootComponent: RootComponent,
  portalContainers: ['banquetPortalsContainer']
  // sentry: {
  //   publicKey: '',
  //   projectId: '',
  //   releaseVersion: process.env.PKG_VERSION
  // }
})

export const bootstrap = reactLifecycles.bootstrap
export const mount = reactLifecycles.mount
export const unmount = reactLifecycles.unmount
export const name = 'ec-admin-navigation-spa'
