import { useMemo, useEffect } from 'react'
import './navigation-items-query'
import { useNavigationItemsQuery } from '../../../generated-code/queries'
import { useCompanyCode } from '@toasttab/ec-session'
import { useLanguage } from '@local/i18n'
import keyBy from 'lodash/keyBy'
import { formatParams } from './helpers'

const useAdminNavigationItems = () => {
  const companyCode = useCompanyCode()

  const { language } = useLanguage()

  const variables = {
    input: {
      params: formatParams({ companyCode }),
      path: 'admin'
    }
  }

  const query = useNavigationItemsQuery({
    variables,
    context: {
      headers: {
        'Accept-Language': language
      }
    }
  })

  const data = query.data

  useEffect(() => {
    if (data) {
      query.refetch(variables)
    }
  }, [language]) // eslint-disable-line react-hooks/exhaustive-deps

  const result = data?.navigation?.result

  const {
    children: adminItems,
    url: adminUrl,
    label: adminLabel
  } = result || {}

  const adminNavigationByName = useMemo(() => {
    return keyBy(adminItems, 'name')
  }, [adminItems])

  return {
    adminNavigationByName,
    adminUrl,
    adminLabel,
    query,
    adminItems
  }
}

export default useAdminNavigationItems
