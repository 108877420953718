import * as React from 'react'
import { Suspense } from 'react'
import { I18nProvider } from '@local/i18n'
import { ApolloProvider } from '@apollo/client'
import { apolloClient } from './apolloClient'
import ErrorBoundary from './ErrorBoundary'
import { BrowserRouter } from 'react-router-dom'

type Props = {
  children: React.ReactNode
}

const Providers = (props: Props) => {
  const { children } = props

  // todo:  add error boundary
  return (
    <ErrorBoundary>
      <Suspense fallback={<></>}>
        <BrowserRouter>
          <I18nProvider>
            <ApolloProvider client={apolloClient}>{children}</ApolloProvider>
          </I18nProvider>
        </BrowserRouter>
      </Suspense>
    </ErrorBoundary>
  )
}

export { Providers }
