import { useEcLocalStorageState } from '@toasttab/ec-storage'
import { useFeature } from '@toasttab/ec-features'

const useNavigationVersion = () => {
  const hasV3Available = useFeature('ec-platform-use-navigation-v3')

  const [
    versionSetting, //
    setVersionSetting
  ] = useEcLocalStorageState('dev-navigation-version')

  const version = hasV3Available ? versionSetting : 'v2'

  type Version = typeof version

  const setVersion = (newVersion: Version) => {
    if (hasV3Available) {
      setVersionSetting(newVersion)
    }
  }

  return {
    version,
    setVersion,
    hasV3Available,
    versionSetting
  }
}

export { useNavigationVersion }
