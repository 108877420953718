import { ApolloClient, InMemoryCache } from '@apollo/client'

const apolloClient = new ApolloClient({
  cache: new InMemoryCache({
    typePolicies: {
      Navigation: {
        keyFields: ['path']
      }
    }
  }),
  uri: '/graphql'
})

export { apolloClient }
