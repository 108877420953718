import * as React from 'react'
import map from 'lodash/map'
import { useAdminNavigationItems } from './hooks'
import { useRoutes } from 'react-router-dom'
import { AdminTabs } from './pages'

const DEFAULT_ROUTE = { path: '*', element: null }

const App = () => {
  const { adminItems, query } = useAdminNavigationItems()

  const routes = map(adminItems, (item) => {
    const { url } = item
    return { path: url, element: <AdminTabs /> }
  })

  const content = useRoutes([...routes, DEFAULT_ROUTE])

  if (query.loading) {
    return null
  }

  return content
}

export { App }
