import * as React from 'react'
import { Providers } from './system'
import { useNavigationVersion } from './hooks'
import { App } from './App'

const FeatureCheck = () => {
  const { version } = useNavigationVersion()

  if (version !== 'v3') return null

  return <App />
}

const RootComponent = () => {
  return (
    <Providers>
      <FeatureCheck />
    </Providers>
  )
}

export { RootComponent }
