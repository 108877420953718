//

const translations = {
  admin: 'Admin'
}

type Translations = typeof translations

export type { Translations }

export { translations }
