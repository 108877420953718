import { gql } from '@apollo/client'
import * as Apollo from '@apollo/client'
export type Maybe<T> = T | null
export type InputMaybe<T> = Maybe<T>
export type Exact<T extends { [key: string]: unknown }> = {
  [K in keyof T]: T[K]
}
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & {
  [SubKey in K]?: Maybe<T[SubKey]>
}
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & {
  [SubKey in K]: Maybe<T[SubKey]>
}
const defaultOptions = {} as const
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string
  String: string
  Boolean: boolean
  Int: number
  Float: number
  /** An `ISO-8601` compliant Full Date Scalar - e.g. 2022-06-01 */
  Date: any
  /** An `ISO-8601` compliant DateTime Scalar, e.g. 2022-06-01T10:15:00.000 */
  DateTime: any
  /** A signed decimal number, which supports arbitrary precision and is serialized as a string. Example values: "29.99", "29.999". */
  Decimal: any
  HashId: any
  LegacyId: any
  SpaDate: any
  SpaDateTime: any
  join__FieldSet: any
  link__Import: any
}

export type AchConsent = {
  __typename?: 'ACHConsent'
  employee: Maybe<BankConsentEmployee>
  needsConsent: Scalars['Boolean']
  payPeriodUuid: Scalars['ID']
  timestamp: Scalars['DateTime']
}

export type ActiveEmploymentStatus = EmploymentStatus & {
  __typename?: 'ActiveEmploymentStatus'
  adjustedHireDate: Maybe<Scalars['String']>
  hireDate: Maybe<Scalars['String']>
}

export type AllEmploymentStatuses =
  | ActiveEmploymentStatus
  | ApplicantEmploymentStatus
  | DeletedEmploymentStatus
  | DemoEmploymentStatus
  | ImplementationEmploymentStatus
  | LeaveOfAbsenceEmploymentStatus
  | NeverEmployedEmploymentStatus
  | PendingEmploymentStatus
  | RehiredEmploymentStatus
  | SeasonalEmploymentStatus
  | TerminatedEmploymentStatus

export type ApplicantEmploymentStatus = EmploymentStatus & {
  __typename?: 'ApplicantEmploymentStatus'
  adjustedHireDate: Maybe<Scalars['String']>
  hireDate: Maybe<Scalars['String']>
}

export type Audit = {
  __typename?: 'Audit'
  action: AuditAction
  date: Scalars['SpaDateTime']
  postponedDate: Maybe<Scalars['String']>
  whom: Scalars['String']
}

export type AuditAction = 'Checks' | 'Opened' | 'Posted' | 'Postponed' | 'Reset'

export type BankAccount = {
  __typename?: 'BankAccount'
  lastFour: Scalars['String']
  name: Scalars['String']
  signatureFileName: Maybe<Scalars['String']>
  uuid: Scalars['String']
}

export type BankAccountConsentInfo = {
  __typename?: 'BankAccountConsentInfo'
  bankAccountUuid: Scalars['ID']
  consents: Maybe<Array<AchConsent>>
  lastFourAccountNumber: Maybe<Scalars['String']>
  payGroupUuid: Scalars['ID']
}

export type BankConsentEmployee = {
  __typename?: 'BankConsentEmployee'
  employeeNumber: Scalars['String']
  firstName: Scalars['String']
  id: Scalars['ID']
  lastName: Scalars['String']
}

export type BulkAddEmployeeResponse = {
  __typename?: 'BulkAddEmployeeResponse'
  status: Scalars['String']
  uuid: Scalars['String']
}

export type BulkAddResponse = {
  __typename?: 'BulkAddResponse'
  employees: Maybe<Array<BulkAddEmployeeResponse>>
}

export type BulkDeductionMutation = {
  amount: Scalars['Float']
  deductionCodeUuid: Scalars['String']
  employeeUuids: InputMaybe<Array<Scalars['String']>>
  isPercentage: Scalars['Boolean']
}

export type BulkEarningMutation = {
  amount: Scalars['Float']
  checkCodeUuid: Scalars['String']
  earningCodeUuid: Scalars['String']
  employeeUuids: Array<Scalars['String']>
  hours: Scalars['Float']
  week: Scalars['Int']
}

export type CashRequirement = {
  __typename?: 'CashRequirement'
  totalEERequirement: EmployeeRequirement
  totalTaxes: Array<PayCheckTaxes>
  vendorNetPays: Array<VendorNetPays>
}

export type ChangePayInput = {
  effectiveDate: Scalars['Date']
  rate: MoneyInput
}

export type CheckCode = {
  __typename?: 'CheckCode'
  label: Scalars['String']
  value: Scalars['String']
}

export type CheckPackage = {
  __typename?: 'CheckPackage'
  numberOfChecks: Scalars['Int']
  shippingAddress: ShippingAddress
  workTaxLocations: Array<WorkTaxLocation>
}

export type CompanyProfile = {
  __typename?: 'CompanyProfile'
  allowedToPost: Scalars['Boolean']
  customerStatus: CustomerStatus
  feins: Array<CustomerFein>
  firstPayrollDate: Scalars['SpaDate']
  numActiveEmployees: Scalars['Int']
  numFeins: Scalars['Int']
  numPayGroups: Scalars['Int']
  numRestaurants: Scalars['Int']
}

export type CompanySummary = {
  __typename?: 'CompanySummary'
  companyProfile: CompanyProfile
  integrationSettings: IntegrationSettings
  payGroups: Array<PayGroupSetting>
  payrollSettings: PayrollSettings
}

export type ContactTelephoneNumber = {
  __typename?: 'ContactTelephoneNumber'
  isMobile: Scalars['Boolean']
  telephoneNumber: Scalars['String']
}

export type Country = {
  __typename?: 'Country'
  code: Scalars['String']
  displayName: Scalars['String']
  isoCode: Scalars['String']
}

/** An `ISO-4217` compliant Currency. */
export type Currency =
  /** Canadian Dollars (CAD). */
  | 'CAD'
  /** Euro (EUR). */
  | 'EUR'
  /** United Kingdom Pounds (GBP). */
  | 'GBP'
  /** United States Dollars (USD). */
  | 'USD'

export type Customer = {
  __typename?: 'Customer'
  locations: Array<Location>
  uuid: Scalars['ID']
}

export type CustomerFein = {
  __typename?: 'CustomerFein'
  dayOfWeek: Scalars['String']
  isPayrollPayor: IsPayrollPayor
  name: Scalars['String']
  tin: Scalars['String']
  uuid: Scalars['String']
}

export type CustomerInput = {
  locations: Array<LocationInput>
  uuid: Scalars['ID']
}

export type CustomerSettings = {
  __typename?: 'CustomerSettings'
  BLENDED_OT: Scalars['Boolean']
  CHECK_PRINTING_PAPER_CHECKS_TYPE: Scalars['Int']
  CHECK_PRINTING_SUFFIX: Scalars['String']
  CREDIT_CARD_TIPS_EARNING_CODE: Scalars['Int']
  DECLARED_EARNINGS_TIPS_EARNINGCODE: Scalars['Int']
  ENFORCE_TWO_FACTOR_AUTHENTICATION: Scalars['String']
  PAYROLL_POST_DEADLINE: Scalars['Int']
  SECONDARY_RATES_CODE_LEVEL: Scalars['Int']
  SECONDARY_RATES_CONSIDER_UNIQUE_LEVELS_SEQUENCE: Scalars['Boolean']
  SECONDARY_RATES_PER_LEVEL: Scalars['Boolean']
  SINGLE_SIGN_ON: Scalars['Boolean']
  TIP_POOLING_CASH_EARNING_CODE: Scalars['Int']
  TIP_POOLING_CASH_GRATUITY_EARNING_CODE: Scalars['Int']
  TIP_POOLING_NON_CASH_EARNING_CODE: Scalars['Int']
  TIP_POOLING_NON_CASH_GRATUITY_EARNING_CODE: Scalars['Int']
}

export type CustomerStatus =
  | 'active'
  | 'conversion'
  | 'demo_company'
  | 'inactive'
  | 'new_client_clean_quarter'
  | 'new_client_mid_quarter'
  | 'none'
  | 'prospect'
  | 'suspect'

export type DateRange = {
  __typename?: 'DateRange'
  endDate: Maybe<Scalars['Date']>
  startDate: Scalars['Date']
}

export type DeletedEmploymentStatus = EmploymentStatus & {
  __typename?: 'DeletedEmploymentStatus'
  adjustedHireDate: Maybe<Scalars['String']>
  hireDate: Maybe<Scalars['String']>
}

export type DeliveryMethod =
  | 'Invalid'
  | 'NotRecorded'
  | 'PayYourOwnWay'
  | 'SelfPrinted'
  | 'ToastPrinted'

export type DemoEmploymentStatus = EmploymentStatus & {
  __typename?: 'DemoEmploymentStatus'
  adjustedHireDate: Maybe<Scalars['String']>
  hireDate: Maybe<Scalars['String']>
}

export type DisabilityStatus = 'DOES_NOT_WISH_TO_ANSWER' | 'NO' | 'YES'

export type DriversLicense = {
  __typename?: 'DriversLicense'
  expirationDate: Scalars['Date']
  number: Scalars['String']
  state: StateInUnitedStates
}

export type EarningCode = {
  __typename?: 'EarningCode'
  isHourly: Scalars['Boolean']
  isOT: Scalars['Boolean']
  label: Scalars['String']
  value: Scalars['String']
}

export type EmergencyContact = {
  __typename?: 'EmergencyContact'
  email: Maybe<Scalars['String']>
  firstName: Scalars['String']
  id: Scalars['ID']
  isPrimary: Maybe<Scalars['Boolean']>
  lastName: Scalars['String']
  relation: Scalars['String']
  telephoneNumber: Maybe<Scalars['String']>
}

export type Employee = {
  __typename?: 'Employee'
  contact: EmployeeContact
  demographics: EmployeeDemographics
  emergencyContacts: Array<EmergencyContact>
  employment: Employment
  id: Scalars['ID']
  identification: EmployeeIdentification
  isEmployeeZero: Scalars['Boolean']
  jobAssignments: Array<JobAssignment>
  payHistory: EmployeePayHistory
  primaryPayGroup: Maybe<PayGroup>
  salary: Maybe<Salary>
  supervisors: Array<EmployeeSupervisor>
  user: Maybe<User>
  /** @deprecated Please use user */
  userUuid: Scalars['ID']
}

export type EmployeeAtLocation = {
  __typename?: 'EmployeeAtLocation'
  jobs: Array<EmployeeJob>
  mappingStatus: MappingCondition
  payrollLocationId: Maybe<LevelId>
  payrollLocationName: Maybe<Scalars['String']>
  posEmail: Maybe<Scalars['String']>
  posLocationGuid: Maybe<Scalars['ID']>
  posMostRecentClockInDate: Maybe<Scalars['Date']>
  posRestaurantUserGuid: Maybe<Scalars['ID']>
  posStatus: Maybe<Scalars['String']>
  posUserName: Maybe<PersonName>
}

export type EmployeeComparison = {
  __typename?: 'EmployeeComparison'
  added: Array<PayrollEmployee>
  removed: Array<PayrollEmployee>
}

export type EmployeeContact = {
  __typename?: 'EmployeeContact'
  homeAddress: Maybe<PostalAddress>
  mailingAddress: Maybe<PostalAddress>
  telephoneNumber: Maybe<ContactTelephoneNumber>
}

export type EmployeeDemographics = {
  __typename?: 'EmployeeDemographics'
  dateOfBirth: Maybe<Scalars['Date']>
  disability: Maybe<DisabilityStatus>
  ethnicity: Maybe<Ethnicity>
  gender: Maybe<Gender>
  maritalStatus: Maybe<MaritalStatus>
  veteranStatus: Array<VeteranStatus>
}

export type EmployeeIdentification = {
  __typename?: 'EmployeeIdentification'
  driversLicense: Maybe<DriversLicense>
  hasSocialSecurityNumber: Scalars['Boolean']
}

export type EmployeeJob = {
  __typename?: 'EmployeeJob'
  mappingStatus: MappingCondition
  payrollId: Maybe<LevelId>
  payrollRateOfPay: Maybe<Money>
  payrollTitle: Maybe<Scalars['String']>
  posGuid: Maybe<Scalars['ID']>
  posRateOfPay: Maybe<Money>
  posTitle: Maybe<Scalars['String']>
}

export type EmployeeMappingStatus = {
  __typename?: 'EmployeeMappingStatus'
  email: Maybe<Scalars['String']>
  id: Scalars['ID']
  issues: Array<MappingIssue>
  locations: Array<EmployeeAtLocation>
  name: PersonName
  status: EmploymentStatusType
}

export type EmployeeNetPays = {
  __typename?: 'EmployeeNetPays'
  amt: Scalars['Float']
  uuid: Scalars['ID']
}

export type EmployeePay = {
  __typename?: 'EmployeePay'
  mostRecentPaycheck: Maybe<Scalars['String']>
}

export type EmployeePayHistory = {
  __typename?: 'EmployeePayHistory'
  mostRecentPaycheck: Maybe<Scalars['String']>
}

export type EmployeeRequirement = {
  __typename?: 'EmployeeRequirement'
  employeesPay: Array<EmployeeNetPays>
  invoiceItems: Array<InvoiceItem>
  voidNetPayment: Array<PayCheck>
}

export type EmployeeSupervisor = {
  __typename?: 'EmployeeSupervisor'
  id: Scalars['ID']
  job: Maybe<Scalars['String']>
  location: Maybe<Scalars['String']>
  name: Scalars['String']
}

export type Employment = {
  __typename?: 'Employment'
  employeeNumber: Maybe<Scalars['String']>
  employmentStatus: AllEmploymentStatuses
  employmentType: Maybe<Scalars['String']>
  /** @deprecated Employment is a property of Employee */
  id: EmploymentId
  totalLengthOfService: Maybe<DateRange>
}

export type EmploymentId = {
  __typename?: 'EmploymentId'
  customerId: Scalars['ID']
  employeeId: Scalars['ID']
}

export type EmploymentStatus = {
  adjustedHireDate: Maybe<Scalars['String']>
  hireDate: Maybe<Scalars['String']>
}

export type EmploymentStatusType =
  | 'ACTIVE'
  | 'APPLICANT'
  | 'DELETED'
  | 'DEMO'
  | 'IMPLEMENTATION'
  | 'LEAVE_OF_ABSENCE'
  | 'NEVER_EMPLOYED'
  | 'NOT_SPECIFIED'
  | 'PENDING_ACTIVE'
  | 'SEASONAL'
  | 'TERMINATED'

export type EnumAmountType = 'Currency' | 'Percent' | 'Undefined' | 'Value'

export type Error = {
  __typename?: 'Error'
  message: Maybe<Scalars['String']>
}

export type Ethnicity =
  | 'AMERICAN_INDIAN_OR_ALASKA_NATIVE'
  | 'ASIAN'
  | 'BLACK_OR_AFRICAN_AMERICAN'
  | 'DO_NOT_WISH_TO_IDENTIFY'
  | 'HAWAIIAN_OR_OTHER_PACIFIC_ISLAND'
  | 'HISPANIC_OR_LATINO'
  | 'TWO_OR_MORE_RACES'
  | 'WHITE'

export type ExpenseReport = {
  __typename?: 'ExpenseReport'
  approveDateTime: Maybe<Scalars['SpaDateTime']>
  customerUuid: Scalars['String']
  employeeFirstName: Scalars['String']
  employeeLastName: Scalars['String']
  employeeUuid: Scalars['String']
  payPeriodUuid: Maybe<Scalars['String']>
  totalAmount: Scalars['Float']
}

export type FeinOption = {
  __typename?: 'FeinOption'
  id: Scalars['LegacyId']
  name: Scalars['String']
}

export type Gender = 'DOES_NOT_WISH_TO_IDENTIFY' | 'FEMALE' | 'MALE'

export type HourlyRetroPay = {
  __typename?: 'HourlyRetroPay'
  hoursWorked: Scalars['Decimal']
  payChangeDifference: Money
  retroPay: Money
}

export type HumanResourcesPermission =
  | 'APPROVE_EMPLOYEE_PROFILE'
  | 'APPROVE_MY_PROFILE'
  | 'APPROVE_STATUS_CHANGES'
  | 'CHANGE_UNVERIFIED_USER_EMAIL'
  | 'CHANGE_VERIFIED_USER_EMAIL'
  | 'EDIT_EMPLOYEE_PROFILE'
  | 'EDIT_MY_PROFILE'
  | 'EDIT_SSN'
  | 'EDIT_STATUS_CHANGES'
  | 'INITIATE_SYNC_TO_POS'
  | 'MIGRATE_USERS'
  | 'SYNC_TO_POS'
  | 'VIEW_EMPLOYEE_PROFILE'
  | 'VIEW_MY_PROFILE'
  | 'VIEW_MY_PROFILE_PUBLIC'
  | 'VIEW_PERMISSIONS'
  | 'VIEW_SSN'
  | 'VIEW_STATUS_CHANGES'

export type ImplementationEmploymentStatus = EmploymentStatus & {
  __typename?: 'ImplementationEmploymentStatus'
  adjustedHireDate: Maybe<Scalars['String']>
  hireDate: Maybe<Scalars['String']>
}

export type IntegrationSettings = {
  __typename?: 'IntegrationSettings'
  active: Scalars['Boolean']
  syncEmployees: Scalars['Boolean']
  tipsType: Maybe<TipsType>
}

export type InvoiceItem = {
  __typename?: 'InvoiceItem'
  total: Scalars['Float']
  uuid: Scalars['ID']
}

export type IsPayrollPayor = 'no' | 'yesall' | 'yesnameonly'

export type IssueSeverity = 'ERROR' | 'INFO' | 'WARN'

export type IssueType =
  | 'ACTIVE_EE_MAPPED_WITH_ARCHIVED_POS'
  | 'JOB_GUID_IS_MALFORMED'
  | 'JOB_IS_MISSING_IN_POS'
  | 'JOB_NOT_MAPPED'
  | 'LOCATION_GUID_IS_MALFORMED'
  | 'LOCATION_IS_MISSING_IN_POS'
  | 'LOCATION_NOT_MAPPED'
  | 'MAPPED_INTO_DIFFERENT_TOAST_IDENTITY'
  | 'PAYROLL_JOBS_MISMATCH_POS_JOBS'
  | 'POS_EE_MAPPING_IGNORED'
  | 'POS_EE_MAPPING_UNKNOWN_LOCATION_GUID'
  | 'RU_COUNTERPART_IS_MISSING_IN_POS'
  | 'UNMAPPED_PAYROLL_SPECIALTY'
  | 'UNMAPPED_POS_RU'

export type JobAssignment = {
  __typename?: 'JobAssignment'
  activeHourlyRateOfPay: Maybe<Money>
  id: Scalars['ID']
  isPrimary: Scalars['Boolean']
  locationName: Scalars['String']
  name: Scalars['String']
  pendingPayChange: Maybe<PendingPayChange>
}

export type JobInput = {
  restaurantJobGuid: InputMaybe<Scalars['ID']>
  uuid: Scalars['ID']
}

export type JobMapping = {
  __typename?: 'JobMapping'
  payrollJob: PayrollJob
  restaurantJob: RestaurantJob
}

export type LeaveOfAbsenceEmploymentStatus = EmploymentStatus & {
  __typename?: 'LeaveOfAbsenceEmploymentStatus'
  adjustedHireDate: Maybe<Scalars['String']>
  hireDate: Maybe<Scalars['String']>
  leaveType: Maybe<Scalars['String']>
  scheduledLeave: Maybe<DateRange>
}

export type LevelConfig = {
  __typename?: 'LevelConfig'
  name: Scalars['String']
  sequence: Scalars['Int']
  type: Scalars['String']
}

export type LevelId = {
  __typename?: 'LevelId'
  databaseId: Scalars['Int']
  sequenceId: Scalars['Int']
}

export type LevelIdInput = {
  databaseId: Scalars['Int']
  sequenceId: Scalars['Int']
}

export type LevelOption = {
  __typename?: 'LevelOption'
  sequenceId: Scalars['Int']
  uuid: Scalars['String']
}

export type Location = {
  __typename?: 'Location'
  name: Maybe<Scalars['String']>
  restaurant: Maybe<Restaurant>
  status: LocationMappingStatus
  uuid: Scalars['ID']
}

export type LocationInput = {
  restaurantGuid: InputMaybe<Scalars['ID']>
  uuid: Scalars['ID']
}

export type LocationMappingStatus = {
  __typename?: 'LocationMappingStatus'
  code: LocationMappingStatusCode
  message: Scalars['String']
}

export type LocationMappingStatusCode =
  | 'NoStatus'
  | 'RestaurantGuidConnected'
  | 'RestaurantGuidDoubleMapped'
  | 'RestaurantGuidEmpty'
  | 'RestaurantGuidInvalid'

export type MappingCondition =
  | 'MAPPED_INVALID_GUID'
  | 'MAPPED_NO_POS_COUNTERPART'
  | 'MAPPED_USER_MAPPED'
  | 'MAPPED_USER_MAPPING_IGNORED'
  | 'MAPPED_USER_NOT_MAPPED'
  | 'NOT_MAPPED'
  | 'POS_ONLY'

export type MappingIssue = {
  __typename?: 'MappingIssue'
  severity: IssueSeverity
  type: IssueType
}

export type MaritalStatus =
  | 'DIVORCED'
  | 'HEAD_OF_HOUSEHOLD'
  | 'MARRIED'
  | 'SINGLE'
  | 'WIDOW'

export type Message = {
  __typename?: 'Message'
  action: MessageActionType
  amount: Scalars['Float']
  amountType: EnumAmountType
  author: Scalars['String']
  datePosted: Scalars['SpaDateTime']
  name: Scalars['String']
  whom: Scalars['String']
}

export type MessageActionType = 'approved' | 'recorded' | 'scheduled'

/** A monetary value with currency. */
export type Money = {
  __typename?: 'Money'
  /** The amount of money */
  amount: Scalars['Decimal']
  /** The currency the money is paid in */
  currency: Currency
}

export type MoneyInput = {
  amount: Scalars['Decimal']
  currency: Currency
}

export type Mutation = {
  __typename?: 'Mutation'
  applyMapping: Scalars['String']
  archivePayPeriod: Maybe<Scalars['String']>
  changeHelloWorldResponse: Maybe<Response>
  changeHourlyPay: Maybe<JobAssignment>
  changeSalary: Maybe<Salary>
  createBulkDeductions: Maybe<BulkAddResponse>
  createBulkEarnings: Maybe<BulkAddResponse>
  deletePendingPayChange: Scalars['String']
  deleteUserMigration: Array<UserMigration>
  migrateUsers: Array<UserMigration>
  open: OpenResponse
  post: PostResponse
  reset: Maybe<Scalars['String']>
  sendJobMappingRequest: Maybe<Scalars['String']>
  updateCustomer: Customer
  updateJobMappings: Array<PayrollJob>
}

export type MutationApplyMappingArgs = {
  locationId: Scalars['ID']
  payrollEmployeeId: Scalars['ID']
  restaurantUserId: InputMaybe<Scalars['ID']>
}

export type MutationArchivePayPeriodArgs = {
  payrollUuid: Scalars['ID']
}

export type MutationChangeHelloWorldResponseArgs = {
  response: Scalars['String']
}

export type MutationChangeHourlyPayArgs = {
  changePayInput: ChangePayInput
  employeeId: Scalars['ID']
  jobAssignmentId: Scalars['ID']
}

export type MutationChangeSalaryArgs = {
  changePayInput: ChangePayInput
  employeeId: Scalars['ID']
}

export type MutationCreateBulkDeductionsArgs = {
  input: BulkDeductionMutation
  payrollUuid: Scalars['ID']
}

export type MutationCreateBulkEarningsArgs = {
  input: BulkEarningMutation
  payrollUuid: Scalars['ID']
}

export type MutationDeletePendingPayChangeArgs = {
  employeeId: Scalars['ID']
  pendingPayChangeId: Scalars['ID']
}

export type MutationDeleteUserMigrationArgs = {
  importId: Scalars['ID']
}

export type MutationMigrateUsersArgs = {
  input: UserMigrationInput
}

export type MutationOpenArgs = {
  payPeriodUuid: Scalars['String']
}

export type MutationPostArgs = {
  consentBankAccountUuids: InputMaybe<Array<Scalars['String']>>
  payPeriodUuid: Scalars['String']
}

export type MutationResetArgs = {
  payPeriodUuid: Scalars['ID']
}

export type MutationSendJobMappingRequestArgs = {
  attachedNote: InputMaybe<Scalars['String']>
  employeeUuid: Scalars['ID']
  payrollJobId: InputMaybe<LevelIdInput>
  payrollLocationId: InputMaybe<LevelIdInput>
}

export type MutationUpdateCustomerArgs = {
  customerInput: CustomerInput
}

export type MutationUpdateJobMappingsArgs = {
  jobs: Array<JobInput>
  locationId: Scalars['ID']
}

export type MutationError = {
  errors: Array<Error>
  status: ResponseStatus
}

export type MutationSuccess = {
  status: ResponseStatus
}

export type Navigation = {
  __typename?: 'Navigation'
  children: Array<Navigation>
  label: Scalars['String']
  matchUrls: Array<Scalars['String']>
  name: Scalars['String']
  path: Scalars['String']
  url: Scalars['String']
}

export type NavigationError = {
  __typename?: 'NavigationError'
  message: Scalars['String']
  type: Scalars['String']
}

export type NavigationErrors = {
  __typename?: 'NavigationErrors'
  errors: Array<NavigationError>
  path: Scalars['String']
}

export type NavigationInput = {
  params: InputMaybe<Array<NavigationParam>>
  path: InputMaybe<Scalars['String']>
}

export type NavigationParam = {
  key: Scalars['String']
  value: Scalars['String']
}

export type NavigationResult = {
  __typename?: 'NavigationResult'
  errors: Array<NavigationErrors>
  result: Maybe<Navigation>
}

export type NeverEmployedEmploymentStatus = EmploymentStatus & {
  __typename?: 'NeverEmployedEmploymentStatus'
  adjustedHireDate: Maybe<Scalars['String']>
  hireDate: Maybe<Scalars['String']>
}

export type NonCashRequirement = {
  __typename?: 'NonCashRequirement'
  employeesManualPay: Array<EmployeeNetPays>
  vendorChecksNetPays: Array<VendorNetPays>
  voidNetPayment: Array<PayCheck>
}

export type OpenError = MutationError & {
  __typename?: 'OpenError'
  errors: Array<Error>
  status: ResponseStatus
}

export type OpenResponse = OpenError | OpenSuccess

export type OpenSuccess = MutationSuccess & {
  __typename?: 'OpenSuccess'
  status: ResponseStatus
}

export type Paged = {
  __typename?: 'Paged'
  pageCount: Scalars['Int']
  pageIndex: Scalars['Int']
  total: Scalars['Int']
}

export type PayCheck = {
  __typename?: 'PayCheck'
  netEarnings: Scalars['Float']
}

export type PayCheckDeliverySettings = {
  __typename?: 'PayCheckDeliverySettings'
  method: DeliveryMethod
  needsPdfFile: Scalars['Boolean']
  usingToastCheckStock: Scalars['Boolean']
}

export type PayCheckTaxes = {
  __typename?: 'PayCheckTaxes'
  amount: Scalars['Float']
  isCollectedByToast: Scalars['Boolean']
  name: Scalars['String']
}

export type PayDetails = {
  __typename?: 'PayDetails'
  checkDate: Maybe<Scalars['SpaDate']>
  gross: Scalars['Float']
  net: Scalars['Float']
  payStubs: Array<PayStub>
  taxes: Scalars['Float']
}

export type PayFrequencies = {
  __typename?: 'PayFrequencies'
  active: Scalars['Boolean']
  calcMethod: Scalars['String']
  default: Scalars['Boolean']
  frequency: Scalars['String']
  name: Scalars['String']
  uuid: Scalars['String']
}

export type PayGroup = {
  __typename?: 'PayGroup'
  id: Scalars['ID']
  name: Scalars['String']
}

export type PayGroupSetting = {
  __typename?: 'PayGroupSetting'
  bankAccount: Maybe<BankAccount>
  name: Scalars['String']
  payFrequencies: Array<PayFrequencies>
  weekStartDay: Maybe<Scalars['String']>
}

export type PayHistoryAudit = {
  __typename?: 'PayHistoryAudit'
  paging: Paged
  records: Maybe<Array<PayHistoryAuditRecord>>
}

export type PayHistoryAuditRecord = {
  __typename?: 'PayHistoryAuditRecord'
  action: Scalars['String']
  modifiedBy: Scalars['String']
  modifiedDate: Scalars['SpaDateTime']
  payPeriod: Scalars['String']
}

export type PayHistoryFilters = {
  feinId: InputMaybe<Scalars['Int']>
  from: InputMaybe<Scalars['SpaDateTime']>
  to: InputMaybe<Scalars['SpaDateTime']>
}

export type PayHistoryPage = {
  __typename?: 'PayHistoryPage'
  FeinList: Array<FeinOption>
  employeeUuid: Scalars['ID']
  latest: Maybe<PayDetails>
  paging: Paged
  payHistory: Maybe<Array<PayDetails>>
}

export type PayHistoryReportType = 'DETAIL' | 'SUMMARY'

export type PayPeriod = {
  __typename?: 'PayPeriod'
  adjustmentUrl: Scalars['String']
  checkCode: CheckCode
  checkCodeName: Scalars['String']
  checkCodeUuid: Scalars['String']
  checkDate: Scalars['SpaDate']
  checkDateOverride: Maybe<Scalars['SpaDate']>
  checkPackages: Maybe<Array<Maybe<CheckPackage>>>
  dashboardUrl: Scalars['String']
  datePosted: Maybe<Scalars['SpaDate']>
  debitDate: Maybe<Scalars['SpaDate']>
  deliveryMethod: DeliveryMethod
  dueDate: Maybe<Scalars['SpaDateTime']>
  employeesSpreadsheetUrl: Scalars['String']
  endDate: Scalars['SpaDate']
  finalCheckDate: Maybe<Scalars['SpaDate']>
  forceLiveChecks: Scalars['Boolean']
  isReadOnly: Maybe<Scalars['Boolean']>
  listUrl: Scalars['String']
  messages: Array<Message>
  name: Scalars['String']
  paperChecksSettingsUrl: Scalars['String']
  /** @deprecated Replaced by combination of dueDate and postDeadline. */
  payrollDate: Maybe<Scalars['SpaDateTime']>
  postDeadline: Scalars['Int']
  postDeadlineText: PostDeadline
  preflightUrl: Scalars['String']
  previewUrl: Scalars['String']
  previousPayPeriodEndDate: Maybe<Scalars['SpaDate']>
  previousPayPeriodStartDate: Maybe<Scalars['SpaDate']>
  previousPayPeriodUuid: Maybe<Scalars['String']>
  reviewUrl: Scalars['String']
  startDate: Scalars['SpaDate']
  status: Scalars['Int']
  taxDebitDate: Scalars['SpaDate']
  taxesImportUrl: Scalars['String']
  timesheetsImportUrl: Scalars['String']
  timesheetsImportUrlAloha: Maybe<Scalars['String']>
  timesheetsUrl: Scalars['String']
  toastPrintedChecks: Maybe<ToastPrinted>
  uuid: Scalars['String']
}

export type PayStub = {
  __typename?: 'PayStub'
  /** @deprecated This function causes contention on the ec-web servers. Use the downloadUrl and pdfDownloadUrl instead */
  DownloadURL: Scalars['String']
  business: Maybe<Scalars['String']>
  checkDate: Scalars['SpaDate']
  checkFile: Scalars['String']
  downloadUrl: Scalars['String']
  employeeUuid: Scalars['ID']
  /** @deprecated if you want the name, use business. If you want fein number, use feinNumber */
  fein: Maybe<Scalars['String']>
  feinNumber: Maybe<Scalars['String']>
  payPeriod: Scalars['String']
  payPeriodId: Scalars['LegacyId']
  payStubNumber: Scalars['Int']
  pdfDownloadUrl: Scalars['String']
}

export type PayStubDownloadUrlArgs = {
  isPDF: InputMaybe<Scalars['Boolean']>
}

/**
 * Represents employees tied to a payroll with a paycheck in a payperiod.
 * Corresponds to PayrollEmployeeSummary type in ec-api.
 */
export type PayrollEmployee = {
  __typename?: 'PayrollEmployee'
  employeeNumber: Scalars['String']
  employeeUuid: Scalars['String']
  firstName: Scalars['String']
  lastName: Scalars['String']
  salaryType: Maybe<SalaryType>
  timesheetTiedToPayrollSetting: Maybe<TimeSheetTiedToPayroll>
  validSsn: Scalars['Boolean']
}

export type PayrollEmployeeMapping = {
  __typename?: 'PayrollEmployeeMapping'
  name: PersonName
  payrollEmployeeNumber: Maybe<Scalars['String']>
}

export type PayrollEmployeePosition = {
  __typename?: 'PayrollEmployeePosition'
  isPrimary: Scalars['Boolean']
  levels: Array<Maybe<PayrollEmployeePositionLevel>>
  uuid: Scalars['String']
}

export type PayrollEmployeePositionLevel = {
  __typename?: 'PayrollEmployeePositionLevel'
  sequenceId: Scalars['Int']
  uuid: Maybe<Scalars['String']>
}

/**
 * Used to represent data for all salaried or hourly employees, regardless of whether they will really appear in paycheck.
 * The type is only used internally, and should be converted to PayrollEmployee if being served publicly.
 */
export type PayrollEmployeeScheduled = {
  __typename?: 'PayrollEmployeeScheduled'
  employeeNumber: Scalars['String']
  employeeUuid: Scalars['String']
  firstName: Scalars['String']
  lastName: Scalars['String']
  salaryType: Maybe<SalaryType>
  timesheetTiedToPayrollSetting: Maybe<TimeSheetTiedToPayroll>
  validSsn: Scalars['Boolean']
}

export type PayrollJob = {
  __typename?: 'PayrollJob'
  name: Scalars['String']
  uuid: Scalars['ID']
}

export type PayrollLevelOption = {
  __typename?: 'PayrollLevelOption'
  isOrphan: Scalars['Boolean']
  name: Scalars['String']
  sequenceId: Scalars['Int']
  uuid: Scalars['String']
}

export type PayrollRequirement = {
  __typename?: 'PayrollRequirement'
  cashRequirement: CashRequirement
  nonCashRequirement: NonCashRequirement
}

export type PayrollSettings = {
  __typename?: 'PayrollSettings'
  customerPrintsW2: Scalars['Boolean']
  importTimesheetEarningsByWeek: Scalars['Boolean']
  matchTimesheetToPayperiod: Scalars['Boolean']
  payrollPayor: IsPayrollPayor
  payrollPostDeadline: PostDeadline
  useBlendedOT: Scalars['Boolean']
}

export type PayrollStatus =
  | 'calculated'
  | 'created'
  | 'editing'
  | 'generated_ach'
  | 'import'
  | 'opened'
  | 'posted'
  | 'posting'
  | 'processing'
  | 'scheduled'
  | 'scheduling'
  | 'unknown'
  | 'verified'

export type PayrollStatusV2 =
  | 'CALCULATED'
  | 'CREATED'
  | 'EDITING'
  | 'EMPLOYEE_MAINTENANCE'
  | 'G2N'
  | 'GENERATED_ACH'
  | 'IMPORT'
  | 'OPENED'
  | 'POSTED'
  | 'POSTING'
  | 'POST_PAYROLL'
  | 'PROCESSING'
  | 'PR_MAINTENANCE'
  | 'REPORTS'
  | 'SCHEDULED'
  | 'SCHEDULING'
  | 'SPREADSHEET'
  | 'STATUS_CHANGES'
  | 'UNKNOWN'
  | 'VACATION'
  | 'VERIFIED'

export type PayrollTotalRequirements = {
  __typename?: 'PayrollTotalRequirements'
  cashRequirement: Scalars['Float']
  nonCashRequirement: Scalars['Float']
  nonDebitedTaxes: Scalars['Float']
}

export type PendingEmploymentStatus = EmploymentStatus & {
  __typename?: 'PendingEmploymentStatus'
  adjustedHireDate: Maybe<Scalars['String']>
  hireDate: Maybe<Scalars['String']>
}

export type PendingPayChange = {
  __typename?: 'PendingPayChange'
  effectiveDate: Scalars['Date']
  id: Scalars['ID']
  rate: Money
}

export type PersonName = {
  __typename?: 'PersonName'
  chosen: Maybe<Scalars['String']>
  first: Maybe<Scalars['String']>
  formatted: Maybe<Scalars['String']>
  last: Maybe<Scalars['String']>
  middle: Maybe<Scalars['String']>
}

export type PosEmployee = {
  __typename?: 'PosEmployee'
  archived: Scalars['Boolean']
  email: Scalars['String']
  externalEmployeeId: Maybe<Scalars['String']>
  mappedToEmployee: Maybe<PayrollEmployeeMapping>
  mostRecentClockInDate: Maybe<Scalars['Date']>
  restaurantUserGuid: Scalars['ID']
  userName: PersonName
}

export type PostDeadline =
  | 'DayOfPayDate'
  | 'FiveDaysBefore'
  | 'FourDaysBefore'
  | 'NotAllowedToPost'
  | 'OneDayBefore'
  | 'ThreeDaysBefore'
  | 'TwoDaysBefore'

export type PostError = MutationError & {
  __typename?: 'PostError'
  errors: Array<Error>
  status: ResponseStatus
}

export type PostResponse = PostError | PostSuccess

export type PostSuccess = MutationSuccess & {
  __typename?: 'PostSuccess'
  status: ResponseStatus
}

export type PostalAddress = {
  __typename?: 'PostalAddress'
  administrativeArea: Scalars['String']
  country: Country
  locality: Scalars['String']
  postalCode: Scalars['String']
  streetAddress: Scalars['String']
  streetAddress2: Maybe<Scalars['String']>
}

export type Query = {
  __typename?: 'Query'
  PayHistory: PayHistoryPage
  PayHistoryAudit: PayHistoryAudit
  PayHistoryReport: Scalars['String']
  PayHistoryReportPDF: Scalars['String']
  audit: Array<Audit>
  bankConsentStatus: Maybe<Array<BankAccountConsentInfo>>
  checkCodes: Array<CheckCode>
  companySummary: CompanySummary
  customer: Customer
  customerSettings: CustomerSettings
  earningCodes: Array<EarningCode>
  /** @deprecated Please use findEmployeeById */
  employee: Employee
  employeePositions: Array<PayrollEmployeePosition>
  findEmployeeById: Maybe<Employee>
  /** @deprecated Please use findEmployeeById */
  findEmployeePay: Maybe<EmployeePay>
  /** @deprecated Please use findEmployeeById */
  findEmployeeSupervisors: Maybe<Supervisors>
  /** @deprecated Please use findEmployeeById */
  findEmployment: Maybe<Employment>
  findJobMappingByJobId: Maybe<JobMapping>
  findJobMappingsByLocationId: Array<JobMapping>
  findPayrollEarningLevels: Array<LevelOption>
  findPayrollJobsByLocationId: Array<PayrollJob>
  findPermissions: Array<HumanResourcesPermission>
  findRestaurantJobsByRestaurantId: Array<RestaurantJob>
  findRetroPayForHourlyJobPayChange: Maybe<HourlyRetroPay>
  findRetroPayForSalaryJobPayChange: Maybe<SalaryRetroPay>
  findUserByEmployeeId: Maybe<User>
  findUserById: Maybe<User>
  /** @deprecated Please use findUserById */
  findUsers: Maybe<Users>
  getApprovedExpenseReports: Array<ExpenseReport>
  getEmployeeComparison: EmployeeComparison
  getEmployeeMappingStatus: EmployeeMappingStatus
  getLevel: Array<PayrollLevelOption>
  /**
   * ALL hourly and salaried associated with a payroll
   * Will only include salaried employees; and hourly employees with a timesheet in the pay period
   * Should NOT be used for periods prior to the latest/currently open, as those will not properly incorporate employees from timesheets
   * See getEmployeeComparison for that use case instead
   */
  getPayrollEmployees: Array<PayrollEmployee>
  getPayrollEmployeesPosted: Array<PayrollEmployee>
  getPosMappingOptions: Array<PosEmployee>
  helloWorld: Response
  levelsConfig: Array<LevelConfig>
  migrations: Array<UserMigration>
  navigation: NavigationResult
  /** retrieves the paycheck shipping settings for a customer */
  paycheckDeliverySettings: PayCheckDeliverySettings
  payperiod: PayPeriod
  payrollRequirement: PayrollTotalRequirements
  restaurant: Maybe<Restaurant>
  sections: SectionResult
  /**
   * Gets the timesheet data for a payperiod.
   * NOTE: The consumer is responsible for any necessary filtering
   * (e.g. checking if timesheets have been imported when considering previous payrolls)
   */
  timesheets: Array<TimesheetByPayrollAggregate>
  upcoming: Array<UpcomingPayroll>
}

export type QueryPayHistoryArgs = {
  employeeUuid: Scalars['ID']
  filters: InputMaybe<PayHistoryFilters>
  first: InputMaybe<Scalars['Int']>
  offset: InputMaybe<Scalars['Int']>
}

export type QueryPayHistoryAuditArgs = {
  employeeUuid: Scalars['ID']
  first: InputMaybe<Scalars['Int']>
  offset: InputMaybe<Scalars['Int']>
}

export type QueryPayHistoryReportArgs = {
  employeeUuid: Scalars['ID']
  feinId: InputMaybe<Scalars['LegacyId']>
  from: InputMaybe<Scalars['SpaDateTime']>
  to: InputMaybe<Scalars['SpaDateTime']>
  type: PayHistoryReportType
}

export type QueryPayHistoryReportPdfArgs = {
  employeeUuid: Scalars['ID']
  feinId: InputMaybe<Scalars['LegacyId']>
  from: InputMaybe<Scalars['SpaDateTime']>
  to: InputMaybe<Scalars['SpaDateTime']>
  type: PayHistoryReportType
}

export type QueryAuditArgs = {
  payrollId: Scalars['ID']
}

export type QueryBankConsentStatusArgs = {
  payPeriodId: InputMaybe<Scalars['ID']>
}

export type QueryEmployeeArgs = {
  employeeUuid: Scalars['ID']
}

export type QueryEmployeePositionsArgs = {
  employeeUuid: Scalars['String']
  payPeriodUuid: Scalars['String']
}

export type QueryFindEmployeeByIdArgs = {
  employeeId: Scalars['ID']
}

export type QueryFindEmployeePayArgs = {
  employeeUuid: Scalars['ID']
}

export type QueryFindEmployeeSupervisorsArgs = {
  employeeUuid: Scalars['ID']
}

export type QueryFindEmploymentArgs = {
  employeeUuid: Scalars['ID']
}

export type QueryFindJobMappingByJobIdArgs = {
  jobId: Scalars['ID']
}

export type QueryFindJobMappingsByLocationIdArgs = {
  locationId: Scalars['ID']
}

export type QueryFindPayrollEarningLevelsArgs = {
  payCheckEarningUuid: Scalars['String']
  payPeriodUuid: Scalars['String']
}

export type QueryFindPayrollJobsByLocationIdArgs = {
  locationId: Scalars['ID']
}

export type QueryFindRestaurantJobsByRestaurantIdArgs = {
  restaurantId: Scalars['ID']
}

export type QueryFindRetroPayForHourlyJobPayChangeArgs = {
  retroPay: RetroPayInput
}

export type QueryFindRetroPayForSalaryJobPayChangeArgs = {
  retroPay: RetroPayInput
}

export type QueryFindUserByEmployeeIdArgs = {
  employeeId: Scalars['ID']
}

export type QueryFindUserByIdArgs = {
  userId: Scalars['ID']
}

export type QueryFindUsersArgs = {
  companyCode: Scalars['ID']
  employeeUuid: Scalars['ID']
}

export type QueryGetApprovedExpenseReportsArgs = {
  payPeriodUuid: Scalars['ID']
}

export type QueryGetEmployeeComparisonArgs = {
  payPeriodUuid: Scalars['ID']
}

export type QueryGetEmployeeMappingStatusArgs = {
  employeeUuid: Scalars['ID']
}

export type QueryGetLevelArgs = {
  levelUuid: Scalars['String']
  sequenceId: Scalars['Int']
}

export type QueryGetPayrollEmployeesArgs = {
  payPeriodUuid: Scalars['ID']
}

export type QueryGetPayrollEmployeesPostedArgs = {
  payPeriodUuid: Scalars['ID']
}

export type QueryGetPosMappingOptionsArgs = {
  employeeUuid: Scalars['ID']
  locationUuid: Scalars['ID']
}

export type QueryNavigationArgs = {
  input: InputMaybe<NavigationInput>
}

export type QueryPayperiodArgs = {
  payPeriodUuid: Scalars['ID']
}

export type QueryPayrollRequirementArgs = {
  payPeriodUuid: Scalars['ID']
}

export type QueryRestaurantArgs = {
  restaurantGuid: Scalars['ID']
}

export type QuerySectionsArgs = {
  input: InputMaybe<SectionsInput>
}

export type QueryTimesheetsArgs = {
  payPeriodUuid: Scalars['ID']
}

export type RehiredEmploymentStatus = EmploymentStatus & {
  __typename?: 'RehiredEmploymentStatus'
  adjustedHireDate: Maybe<Scalars['String']>
  dismissalPeriod: Maybe<DateRange>
  hireDate: Maybe<Scalars['String']>
  rehireDate: Maybe<Scalars['String']>
}

export type Response = {
  __typename?: 'Response'
  response: Maybe<Scalars['String']>
}

export type ResponseStatus = 'FAILED' | 'SUCCESS'

export type Restaurant = {
  __typename?: 'Restaurant'
  guid: Scalars['ID']
  name: Maybe<Scalars['String']>
}

export type RestaurantJob = {
  __typename?: 'RestaurantJob'
  guid: Scalars['ID']
  name: Scalars['String']
  status: RestaurantJobStatusCode
}

export type RestaurantJobStatusCode =
  | 'Available'
  /** Restaurant job does not exist */
  | 'Deleted'

export type RetroPayInput = {
  employeeId: Scalars['ID']
  endDate: Scalars['Date']
  jobAssignmentId: Scalars['ID']
  newRate: MoneyInput
  oldRate: MoneyInput
  startDate: Scalars['Date']
}

export type Salary = {
  __typename?: 'Salary'
  activeSalaryRateOfPay: Maybe<Money>
  id: Scalars['ID']
  pendingPayChange: Maybe<PendingPayChange>
}

export type SalaryRetroPay = {
  __typename?: 'SalaryRetroPay'
  payChangeDifference: Money
  payPeriodEarnings: Money
  retroPay: Money
}

export type SalaryType =
  | 'commission'
  | 'draw'
  | 'hourly'
  | 'salary'
  | 'ten99'
  | 'unknown'
  | 'w2'

export type SeasonalEmploymentStatus = EmploymentStatus & {
  __typename?: 'SeasonalEmploymentStatus'
  adjustedHireDate: Maybe<Scalars['String']>
  hireDate: Maybe<Scalars['String']>
}

export type Section = {
  __typename?: 'Section'
  items: Array<Navigation>
  label: Scalars['String']
  name: Scalars['String']
}

export type SectionResult = {
  __typename?: 'SectionResult'
  errors: Array<NavigationErrors>
  result: Array<Section>
}

export type SectionsInput = {
  params: InputMaybe<Array<NavigationParam>>
}

export type ShippingAddress = {
  __typename?: 'ShippingAddress'
  addressLine1: Scalars['String']
  addressLine2: Scalars['String']
  attention: Scalars['String']
  city: Scalars['String']
  recipientCompany: Scalars['String']
  shippingAddressUuid: Scalars['String']
  state: Scalars['String']
  stateName: Scalars['String']
  trackingNumber: Maybe<Scalars['String']>
  zipCode: Scalars['String']
}

export type StateInUnitedStates = {
  __typename?: 'StateInUnitedStates'
  abbreviation: Scalars['String']
  fipsCode: Scalars['Int']
  fullName: Scalars['String']
}

export type Supervisor = {
  __typename?: 'Supervisor'
  id: EmploymentId
  job: Maybe<Scalars['String']>
  location: Maybe<Scalars['String']>
  name: Scalars['String']
}

export type Supervisors = {
  __typename?: 'Supervisors'
  supervisors: Array<Supervisor>
}

export type TerminatedEmploymentStatus = EmploymentStatus & {
  __typename?: 'TerminatedEmploymentStatus'
  adjustedHireDate: Maybe<Scalars['String']>
  hireDate: Maybe<Scalars['String']>
  lastDayOfEmployment: Maybe<Scalars['String']>
  lastDayToReceiveBenefits: Maybe<Scalars['String']>
}

export type TimeSheetStatus =
  | 'approved'
  | 'deleted'
  | 'denied'
  | 'open'
  | 'pending_approval'
  | 'returned'
  | 'unknown'

export type TimeSheetTiedToPayroll = 'all' | 'no' | 'ot' | 'tafwot'

export type TimesheetByPayrollAggregate = {
  __typename?: 'TimesheetByPayrollAggregate'
  earningEntries: Array<TimesheetEarningEntry>
  employeeUuid: Scalars['String']
  isImported: Scalars['Boolean']
  status: Maybe<TimeSheetStatus>
}

export type TimesheetEarningEntry = {
  __typename?: 'TimesheetEarningEntry'
  amount: Maybe<Scalars['Float']>
  date: Maybe<Scalars['SpaDate']>
  earningCode: TimesheetEntryEarningCode
  hoursWorked: Maybe<Scalars['Float']>
  isOvertime: Scalars['Boolean']
}

export type TimesheetEntryEarningCode = {
  __typename?: 'TimesheetEntryEarningCode'
  isHourly: Maybe<Scalars['Boolean']>
  name: Scalars['String']
  timeAwaySetupId: Scalars['Int']
  uuid: Scalars['ID']
}

export type TipsType = 'none' | 'normal' | 'pooled'

export type ToastPrinted = {
  __typename?: 'ToastPrinted'
  totalChecks: Scalars['Int']
  totalEmployees: Scalars['Int']
}

export type UpcomingPayroll = {
  __typename?: 'UpcomingPayroll'
  dueDate: Maybe<Scalars['DateTime']>
  endDate: Scalars['Date']
  id: Scalars['ID']
  manual: Scalars['Boolean']
  name: Scalars['String']
  payDate: Scalars['Date']
  startDate: Scalars['Date']
  status: PayrollStatusV2
  unApprovedTimesheetsCount: Scalars['Int']
}

export type User = {
  __typename?: 'User'
  email: Maybe<Scalars['String']>
  employeeUuid: Maybe<Scalars['ID']>
  id: Scalars['ID']
  isEmailVerified: Scalars['Boolean']
  name: PersonName
  permissions: Array<HumanResourcesPermission>
  toastIdentityGuid: Maybe<Scalars['ID']>
  username: Scalars['String']
}

export type UserMigration = {
  __typename?: 'UserMigration'
  companyCode: Scalars['String']
  customerUuid: Scalars['ID']
  error: UserMigrationError
  importId: Scalars['ID']
  status: UserMigrationStatus
  updatedAt: Scalars['DateTime']
  userUuid: Scalars['ID']
}

export type UserMigrationError =
  | 'AUTHENTICATION_PROVIDER'
  | 'DATABASE_WRITE_FAILURE'
  | 'EMAIL_CONFLICT'
  | 'EXPIRED'
  | 'GUID_CONFLICT'
  | 'NONE'
  | 'UNKNOWN'

export type UserMigrationInput = {
  companyCodes: Array<Scalars['String']>
}

export type UserMigrationStatus = 'ERROR' | 'IMPORTING' | 'STARTED'

export type Users = {
  __typename?: 'Users'
  list: Array<Maybe<User>>
}

export type VendorNetPays = {
  __typename?: 'VendorNetPays'
  amount: Scalars['Float']
}

export type VeteranStatus =
  | 'ACTIVE_WAR_TIME_OR_CAMPAIGN_BADGE_VETERAN'
  | 'ARMED_FORCES_SERVICE_MEDAL_VETERAN'
  | 'DISABLED_VETERAN'
  | 'NOT_A_VETERAN'
  | 'RECENTLY_SEPARATED_VETERAN'
  | 'SPECIAL_DISABLED_VETERAN'
  | 'VETERAN'
  | 'VIETNAM_ERA_VETERAN'

export type WorkTaxLocation = {
  __typename?: 'WorkTaxLocation'
  customerUuid: Scalars['String']
  name: Scalars['String']
  shippingAddressUuid: Scalars['String']
}

export type Join__Graph =
  | 'CUSTOMER'
  | 'EC_HUMAN_RESOURCES'
  | 'EC_JOBS'
  | 'EC_PAYROLL'
  | 'EC_WEB'
  | 'NAVIGATION'
  | 'PAYROLL'

export type Link__Purpose =
  /** `EXECUTION` features provide metadata necessary for operation execution. */
  | 'EXECUTION'
  /** `SECURITY` features provide metadata necessary to securely resolve fields. */
  | 'SECURITY'

export type NavigationItemsQueryVariables = Exact<{
  input: NavigationInput
}>

export type NavigationItemsQuery = {
  __typename?: 'Query'
  navigation: {
    __typename?: 'NavigationResult'
    result: {
      __typename?: 'Navigation'
      path: string
      name: string
      label: string
      url: string
      matchUrls: Array<string>
      children: Array<{
        __typename?: 'Navigation'
        path: string
        name: string
        label: string
        url: string
        matchUrls: Array<string>
      }>
    } | null
    errors: Array<{
      __typename?: 'NavigationErrors'
      path: string
      errors: Array<{
        __typename?: 'NavigationError'
        type: string
        message: string
      }>
    }>
  }
}

export const NavigationItemsDocument = gql`
  query NavigationItems($input: NavigationInput!) {
    navigation(input: $input) {
      result {
        path
        name
        label
        url
        matchUrls
        children {
          path
          name
          label
          url
          matchUrls
        }
      }
      errors {
        path
        errors {
          type
          message
        }
      }
    }
  }
`

/**
 * __useNavigationItemsQuery__
 *
 * To run a query within a React component, call `useNavigationItemsQuery` and pass it any options that fit your needs.
 * When your component renders, `useNavigationItemsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useNavigationItemsQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useNavigationItemsQuery(
  baseOptions: Apollo.QueryHookOptions<
    NavigationItemsQuery,
    NavigationItemsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<NavigationItemsQuery, NavigationItemsQueryVariables>(
    NavigationItemsDocument,
    options
  )
}
export function useNavigationItemsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    NavigationItemsQuery,
    NavigationItemsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    NavigationItemsQuery,
    NavigationItemsQueryVariables
  >(NavigationItemsDocument, options)
}
export type NavigationItemsQueryHookResult = ReturnType<
  typeof useNavigationItemsQuery
>
export type NavigationItemsLazyQueryHookResult = ReturnType<
  typeof useNavigationItemsLazyQuery
>
export type NavigationItemsQueryResult = Apollo.QueryResult<
  NavigationItemsQuery,
  NavigationItemsQueryVariables
>

export interface PossibleTypesResultData {
  possibleTypes: {
    [key: string]: string[]
  }
}
const result: PossibleTypesResultData = {
  possibleTypes: {
    AllEmploymentStatuses: [
      'ActiveEmploymentStatus',
      'ApplicantEmploymentStatus',
      'DeletedEmploymentStatus',
      'DemoEmploymentStatus',
      'ImplementationEmploymentStatus',
      'LeaveOfAbsenceEmploymentStatus',
      'NeverEmployedEmploymentStatus',
      'PendingEmploymentStatus',
      'RehiredEmploymentStatus',
      'SeasonalEmploymentStatus',
      'TerminatedEmploymentStatus'
    ],
    EmploymentStatus: [
      'ActiveEmploymentStatus',
      'ApplicantEmploymentStatus',
      'DeletedEmploymentStatus',
      'DemoEmploymentStatus',
      'ImplementationEmploymentStatus',
      'LeaveOfAbsenceEmploymentStatus',
      'NeverEmployedEmploymentStatus',
      'PendingEmploymentStatus',
      'RehiredEmploymentStatus',
      'SeasonalEmploymentStatus',
      'TerminatedEmploymentStatus'
    ],
    MutationError: ['OpenError', 'PostError'],
    MutationSuccess: ['OpenSuccess', 'PostSuccess'],
    OpenResponse: ['OpenError', 'OpenSuccess'],
    PostResponse: ['PostError', 'PostSuccess']
  }
}
export default result
