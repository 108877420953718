import { gql } from '@apollo/client'

gql`
  query NavigationItems($input: NavigationInput!) {
    navigation(input: $input) {
      result {
        path
        name
        label
        url
        matchUrls
        children {
          path
          name
          label
          url
          matchUrls
        }
      }
      errors {
        path
        errors {
          type
          message
        }
      }
    }
  }
`
