import { useState, useEffect, useMemo } from 'react'

import { useEcProps } from '@toasttab/ec-banquet-props'

const useI18nLanguage = () => {
  const { i18n } = useEcProps()

  const [language, setLanguage] = useState<string>(i18n.language)

  useEffect(() => {
    const onChange = (lng: string) => setLanguage(lng)

    i18n.on('languageChanged', onChange)

    return () => i18n.off('languageChanged', onChange)
  }, [i18n])

  const value = useMemo(() => {
    return { i18n, language }
  }, [i18n, language])

  return value
}

export { useI18nLanguage }
