import * as React from 'react'
import { useMemo, createContext, useContext } from 'react'
import { TranslationProvider } from '@toasttab/buffet-pui-translation-utilities'
import { translationLoader } from './locales'
import { useI18nLanguage } from './useI18nLanguage'

type Value = { language: string }

const DEFAULT_VALUE = { language: 'en-US' }

const LangContext = createContext<Value>(DEFAULT_VALUE)

type Props = {
  children: React.ReactNode
}

const I18nProvider = (props: Props) => {
  const { children } = props

  const { language, i18n } = useI18nLanguage()

  const value = useMemo(() => ({ language }), [language])

  // the key here is being passed is kick the re-translation.
  // really, this should be handled by the `TranslationProvider` from buffet
  return (
    <LangContext.Provider value={value}>
      <TranslationProvider
        key={language}
        namespace='ec-admin-navigation-spa'
        i18n={{ i18next: i18n }}
        translationLoader={translationLoader}
      >
        {children}
      </TranslationProvider>
    </LangContext.Provider>
  )
}

export const useLanguage = () => useContext(LangContext)

export default I18nProvider
