import * as React from 'react'
import map from 'lodash/map'
import { useAdminNavigationItems } from '../hooks'
import { EcPageHeader } from '@toasttab/ec-ui-page-header'
import { EcNavigationTabs } from '@toasttab/ec-ui-navigation-tabs'

const TABS = ['client', 'payroll', 'benefits', 'implementation']

const AdminTabs = () => {
  const {
    adminNavigationByName, //
    adminLabel,
    query
  } = useAdminNavigationItems()

  if (query.loading) {
    return null
  }

  const tabs = map(TABS, (tab) => {
    const item = adminNavigationByName[tab]
    const { label, url, path } = item || {}

    return (
      <EcNavigationTabs.Tab
        key={`${tab}-${path}`}
        href={url}
        label={label || tab}
      />
    )
  })

  return (
    <EcPageHeader
      title={adminLabel || 'Admin'}
      tabs={<EcNavigationTabs ecTitle={adminLabel}>{tabs}</EcNavigationTabs>}
    />
  )
}

export default AdminTabs
