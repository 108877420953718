import each from 'lodash/each'

type Param = { key: string; value: string }

const formatParams = (args: Record<string, string | undefined | null>) => {
  const result: Param[] = []

  each(args, (value, key) => {
    if (value) result.push({ key, value })
  })

  return result
}

export { formatParams }
